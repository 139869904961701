<template>
  <div class="workouts-slider-wrapper section-padding">
    <div class="container">
      <div class="workouts-slider-holder">
        <div class="workouts-slider-heading d-flex justify-content-between align-items-center">
          <p class="section-title">Treinos Relacionados</p>
          <div class="d-flex justify-content-end align-items-center">
            <router-link to="/workout-by-list" class="heading-link">Ver todos</router-link>
            <div class="workouts-slider-arrows d-none d-xl-flex align-items-center justify-content-center">
              <button type="button" class="workouts-slider-arrow workouts-slider-prev" @click="prevSlide">
                <chevronLeft/>
              </button>
              <button type="button" class="workouts-slider-arrow workouts-slider-next" @click="nextSlide">
                <chevronLeft/>
              </button>
            </div>
          </div>
        </div>
        <Slick ref="slick" :options="slickOptions" class="workouts-slider">
          <div class="workouts-slider-item" v-for="(item,index) in workoutRelatedItems" :key="index">
            <workoutItem :workoutItem="item"/>
          </div>
        </Slick>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import workoutItem from "@/components/workouts/workout-item.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import axios from "axios";

export default {
  name: "products-slider",
  props: ['related'],
  components : {
    Slick,
    workoutItem,
    chevronLeft
  },
  data() {
    return {
      lang: this.$root.siteLang,
      workoutRelatedItems: this.related,
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        variableWidth: true
      }
    }
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
  },
  watch: {
    related () {
      this.workoutRelatedItems = this.related
    }
  },
  created() {
  //   let authKey = window.$cookies.get("authKey");
  //
  //   axios
  //       .get(process.env.VUE_APP_API_URL + 'products?id_category=1&with_category_title=0&lang=' + this.lang,{
  //         headers: {
  //           Authorization: 'Bearer ' + authKey
  //         }
  //       })
  //       .then((response) => {
  //         this.workoutRelatedItems = response.data;
  //       })
  //       .catch((e) => {
  //         this.errors.push(e);
  //       }).finally((e) => {
  //     (this.workoutRelatedItems.length > 0) ? this.showWorkoutRelated = true : this.showWorkoutRelated = false;
  //   });
   },
}
</script>