<template>
  <div class="dashboard workout-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back" @click="$router.go(-1)">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">{{ exerciseItem.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-7">
                <div class="workout-detail-image" v-on="(exerciseItem.media && exerciseItem.media.videos[0]) ? { click: toggleVideoModal } : {}" :class = "( exerciseItem.media && exerciseItem.media.videos[0] ) ? 'has-video' : '' ">
                  <img :src="exerciseItem.media.general[0].image" alt="" v-if="exerciseItem.media && exerciseItem.media.general">
                  <div class="workout-detail-image-content">
                    <div class="container">
                      <div class="d-flex justify-content-start align-items-end">
                        <div class="workout-detail-video-btn">
                          <playIcon/>
                        </div>
                        <div class="d-lg-none">
                          <p class="workout-detail-title">{{ exerciseItem.title }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="workout-detail-text-wrapper" v-if="exerciseItem.description">
                  <div class="container">
                    <p class="workout-detail-text-title">Instruções Exercicio</p>
                    <div class="workout-detail-text-description-wrapper">
                      <div class="workout-detail-text-description" v-html="exerciseItem.description"></div>
                      <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                        <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                          <p>Ler Mais</p>
                          <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <workoutsRelated :related=exerciseItem.related />
    <equipmentBlock :parentItem="exerciseItem" :equipment="exerciseItem.equipments" v-if="exerciseItem.equipments  && exerciseItem.equipments.length > 0" />
    <div class="workout-detail-btn-wrapper d-lg-none" v-if="exerciseItem.media">
      <div class="container d-flex justify-content-center align-items-center">
        <button type="button" class="generic-btn red-gradient workout-detail-btn" @click="toggleVideoModal">Iniciar Exercicio</button>
      </div>
    </div>
    <videoModal :video="exerciseItem.media.videos[0]" :productID="exerciseItem.id" v-if="exerciseItem.media.videos" />
<!--    <feedbackModal :productID="exerciseItem.id" />-->
  </div>
</template>

<script>
import equipmentBlock from "@/components/equipment/equipment-block.vue";
import workoutsRelated from "@/components/workouts/workouts-related.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import bookmark from "@/components/svg/bookmark.vue";
import shareIcon from "@/components/svg/share-icon.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import playIcon from "@/components/svg/play.vue";
import videoModal from "@/components/modals/video-modal.vue";
//import feedbackModal from "@/components/modals/feedback-modal.vue";

import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  name: "exercise-detail",
  data () {
    return {
      lang: this.$root.siteLang,
      exerciseItem: [],
    }
  },
  components : {
    equipmentBlock,
    workoutsRelated,
    chevronLeft,
    bookmark,
    shareIcon,
    chevronDown,
    playIcon,
    videoModal,
    // feedbackModal
  },
  methods: {
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
    toggleVideoModal() {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
        //document.getElementsByClassName("box-feedback-modal")[0].classList.add("open");
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
      }
    }
  },
  mounted() {
    EventBus.$on('bus-toggleVideoModal', () => {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
        //document.getElementsByClassName("box-feedback-modal")[0].classList.add("open");
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
      }
    });
  },
  created() {
    const areaSlugId = this.$route.params.id;
    const areaSlugParts = areaSlugId.split('-');
    const idAreaItem = areaSlugParts[areaSlugParts.length - 1];

    axios
        .get(process.env.VUE_APP_API_URL + 'products/'+ idAreaItem + '?id_category=3&with_category_title=0&lang=' + this.lang)
        .then((response) => {
          this.exerciseItem = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>